<template lang="pug">
ul(role="tablist")
  slot(v-bind="slotProps")
</template>

<script setup>
const props = defineProps({
  localeUrls: {
    type: Object,
    default: () => ({})
  },
  slug: {
    type: String,
    default: 'tab'
  },
  isMember: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['onTabChange'])

const { urls: localeUrlsRaw } = useLocale()
const route = useRoute()
const currentSlug = ref(route.params.slug)
const activeTab = ref(null)

const updateActiveTab = () => {
  activeTab.value = props.isMember
    ? findKeyBySlug(currentSlug.value, localeUrlsRaw.authSlug)
    : findActiveSlugKey(props.localeUrls, route.params, props.slug)
  emit('onTabChange', activeTab.value)
}

onMounted(() => {
  updateActiveTab()
})

watch(route, () => {
  updateActiveTab()
})

const slotProps = computed(() => ({
  activeTab: activeTab.value
}))

</script>